
.validation-error{
    color: black !important;
    border:1px solid red!important;

    &::placeholder{
        color: red !important;
    }
}

.disable-form{
    width:100%;
    height:-webkit-fill-available;
    display: flex;
    background-color: rgba(255,255,255,.75);
    position:absolute;
    z-index: 3;
}

.multi-select-lg{
    min-height: 120px;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



.margin-auto{
    margin:0 auto;
}

.toast-bottom-right{
    position: fixed!important;
    top:1em!important;
    right: 1em!important;

}

.no-pad{
    padding-top:0;padding-bottom: 0;
}
