
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$endless-primary: mat.define-palette(mat.$indigo-palette);
$endless-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$endless-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$endless-theme: mat.define-light-theme((
  color: (
    primary: $endless-primary,
    accent: $endless-accent,
    warn: $endless-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($endless-theme);

// @import '~animate.css/animate.min.css';

// @import "/node_modules/chartist/dist/index.css";

@import "/node_modules/bootstrap/scss/bootstrap.scss";

@import "theme/variables";
@import "style";
@import "theme/responsive";
@import "theme/dashboard_default";

@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "chartist/chartist";

@import "/node_modules/angular-calendar/css/angular-calendar.css";
@import "/node_modules/flatpickr/dist/flatpickr.css";

@import "todo/todo";
@import "~leaflet/dist/leaflet.css";
@import "/node_modules/ngx-toastr/toastr.css";
@import "sweetalert/sweetalert2.scss";
@import "~dragula/dist/dragula.css";
@import "sticky/sticky.scss";
@import "~dropzone/dist/min/dropzone.min.css";

